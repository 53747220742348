import request from "../http/request";

//获取验证码
export const agentSms = (data) => {
  return request({
    url: "/api/copartner/app/send/share/sms",
    method: "post",
    data,
  });
};

//注册
export const agentAdd = (data) => {
  return request({
    url: "/api/copartner/app/register",
    method: "post",
    data,
  });
};

// 图形验证码
export const getCaptcha = () => {
  return request({
    url: "/api/auth/admin/index/captcha",
    method: "get",
  });
};

// 下载
export const getAppDown = (params) => {
  return request({
    url: "/api/resource/update/packages/package",
    method: "get",
    params,
  });
};
